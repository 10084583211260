import React from "react";
import styles from "./followups.module.css";
import { useState, useEffect, useRef } from "react";
import FilterMenu from "../universal_components/filter_menu";
import SearchBarHeader from "../universal_components/search_bar_header";
import dummy_prospect_data from "../../../data/prospect_data";
import FollowUpCard from "./followupcard";

import TabHighlight from "../universal_components/tab_highlight";
import ManageDefaultFollowUps from "../../screen_popups/ManageDefaultFollowUps/ManageDefaultFollowUps";

import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

import CircularLoader from "../universal_components/CircularLoader/CircularLoader";
import PageControl from "../universal_components/PageContols/PageControl";

import InfoIconStroke from "../../../assets/svg/InfoIconStroke";

function FollowUps() {
  const {
    followUps,
    profile,
    fetchUserFollowUps,
    followUpCount,
    isLastPage,
    callScheduledCount,
    followUpsActiveCount,
  } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState();
  const [sortBy, setSortBy] = useState("All");
  const [isEditing, setIsEditing] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [isLoading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [defaultFollowUpsSchedule, setDefaultFollowUpsSchedule] = useState(
    profile?.default_follow_ups || FollowUpMessageOptions
  );

  const prevFollowUpCountRef = useRef(followUpCount);
  const prevCallScheduledCountRef = useRef(callScheduledCount);
  const prevFollowUpsActiveCountRef = useRef(followUpsActiveCount);

  useEffect(() => {
    if (profile?.default_follow_ups) {
      setDefaultFollowUpsSchedule(profile.default_follow_ups);
    }
  }, [profile]);

  function handleEdit() {
    setIsEditing(!isEditing);
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm === "" ? null : searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Fetch data when debouncedSearchTerm or currentPage changes
  useEffect(() => {
    const fetchData = async () => {
      if (!profile || !profile.id) return; // Ensure profile is valid

      setLoading(true);
      try {
        await fetchUserFollowUps(debouncedSearchTerm, currentPage, 50, sortBy);
      } catch (error) {
        console.error("Error fetching follow-ups:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [debouncedSearchTerm, currentPage, profile, sortBy]);

  useEffect(() => {
    prevFollowUpCountRef.current = followUpCount;
    prevCallScheduledCountRef.current = callScheduledCount;
    prevFollowUpsActiveCountRef.current = followUpsActiveCount;
  }, [followUpCount, callScheduledCount, followUpsActiveCount]);

  function handlePrevPage() {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  }

  function handleNextPage() {
    setCurrentPage((prevPage) => prevPage + 1);
  }

  return (
    <div className={styles.main_layout}>
      <ManageDefaultFollowUps
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        profile={profile}
        defaultFollowUpsSchedule={defaultFollowUpsSchedule}
        setDefaultFollowUpsSchedule={setDefaultFollowUpsSchedule}
        // followUps={followUp.followUpSchedule}
        // prospect={prospect}
        // isLoading={isLoading}
      />

      <p className={styles.page_title}>
        Follow Ups{" "}
        <span
          className={`${styles.page_title_sub} ${
            followUpCount !== prevFollowUpCountRef.current ? styles.glow : ""
          }`}
        >
          Total {followUpCount}
        </span>
        <span
          className={`${styles.page_title_sub} ${
            followUpCount - followUpsActiveCount !==
            followUpCount - followUpsActiveCount.current
              ? styles.glow
              : ""
          }`}
        >
          Active {followUpCount - followUpsActiveCount}
        </span>
        <span
          className={`${styles.page_title_sub} ${
            followUpsActiveCount !== prevFollowUpsActiveCountRef.current
              ? styles.glow
              : ""
          }`}
        >
          Stopped {followUpsActiveCount}
        </span>
        <span
          className={`${styles.page_title_sub} ${
            callScheduledCount !== prevCallScheduledCountRef.current
              ? styles.glow
              : ""
          }`}
        >
          Calls Scheduled {callScheduledCount}
        </span>
      </p>
      <div className={styles.info_row}>
        <InfoIconStroke width={16} height={16} />
        <p className={styles.info_row_text}>
          If the prospect is the last person to send a message, no follow ups
          will be sent. Which means, you need to reply to continue the
          conversation.
        </p>
      </div>
      <div className={styles.info_row}>
        <InfoIconStroke width={16} height={16} />
        <p className={styles.info_row_text}>
          If you are the last person to send a message, the follow ups will be
          sent based on your Default Follow Up Schedule (see "Manage Default
          Follow Ups" button).
        </p>
      </div>
      <div className={styles.spacer}></div>
      <SearchBarHeader
        filterType="followups"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setIsEditing={setIsEditing}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />

      <div className={styles.follow_up_content}>
        <div className={styles.column_titles}>
          <p className={styles.column_title_medium}>Prospect</p>
          <p className={styles.column_title_mid}>Next Due Date</p>
          <p className={styles.column_title_large}>Next Message</p>
        </div>
        {!isLoading ? (
          <>
            {followUps.map((followup, index) => {
              return <FollowUpCard key={index} followup={followup} />;
            })}
            <PageControl
              prevFunction={handlePrevPage}
              nextFunction={handleNextPage}
              isLastPage={isLastPage}
            />
          </>
        ) : (
          <CircularLoader />
        )}
      </div>
    </div>
  );
}

export default FollowUps;

const FollowUpMessageOptions = [
  { delay: 1, message: "Thoughts on that last message of mine, by the way?" },
  {
    delay: 2,
    message:
      "Shooting a message your way to keep our conversation fresh & near the top of your inbox!",
  },
  { delay: 2, message: "[firstName]?" },
  { delay: 4, message: "Hey [firstName], remember me?" },
  { delay: 4, message: "Any chance now is a better time to talk?" },
  {
    delay: 8,
    message: "Maybe this message will finally be the right timing? 😂",
  },
  {
    delay: 8,
    message:
      "Hey, I think I've missed the mark with my outreach. To try and spare anyone else from ineffective messaging, I'm curious - what could I have done differently to grab your attention?",
  },
  {
    delay: 16,
    message: "Would it be best for me to not reach out going forward?",
  },
];
