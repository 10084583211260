import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useContext, useEffect } from "react";
import App from "./App";

// import NotFound from "./pages/NotFound";
import AdminSignInPage from "./pages/Authentication/AdminSignInPage";

import DashboardPage from "./pages/Portal/DashboardPage";
import CampaignsPage from "./pages/Portal/CampaignsPage";
import InboxPage from "./pages/Portal/InboxPage";
import FollowUpsPage from "./pages/Portal/FollowUpsPage";
import DealStagesPage from "./pages/Portal/DealStagesPage";
import ProspectsPage from "./pages/Portal/ProspectsPage";
import IntegrationsPage from "./pages/Portal/IntegrationsPage";
import SettingsPage from "./pages/Portal/SettingsPage";
import { AuthContext } from "./context/AuthContext";

import FeedbackPage from "./pages/Portal/FeedbackPage";

function PrivateRoute({ children }) {
  const { currentUser, userId, setUserId, fetchUserFollowUps, fetchUserData } =
    useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userID = params.get("userID");
    console.log("Route UseEffect", userID);
    if (userID) {
      setUserId(userID);
      fetchUserFollowUps(userID);
      fetchUserData(userID);
    }
  }, [location.search, setUserId]);

  const isUserAuthenticated = userId || currentUser;

  return children;
}

function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* ROUTES FOR LANDING PAGE */}
          <Route path="/" element={<App childComp={<AdminSignInPage />} />} />

          {/* <Route exact element={<ProtectedRoutes />}> */}

          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <App childComp={<DashboardPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/campaigns"
            element={
              <PrivateRoute>
                <App childComp={<CampaignsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/inbox"
            element={
              <PrivateRoute>
                <App childComp={<InboxPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/followups"
            element={
              <PrivateRoute>
                <App childComp={<FollowUpsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/dealstages"
            element={
              <PrivateRoute>
                <App childComp={<DealStagesPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/prospects"
            element={
              <PrivateRoute>
                <App childComp={<ProspectsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/integrations"
            element={
              <PrivateRoute>
                <App childComp={<IntegrationsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/feedback"
            element={<App childComp={<FeedbackPage />} />}
          />
          {/* <Route
            path="/chatbottesting"
            element={<App childComp={<ChatbotTestingPage />} />}
          /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;
