import React from "react";
import { useContext, useState } from "react";
import styles from "./universal.module.css";
// import FilterMenu from "../universal_components/filter_menu";
import { AuthContext } from "../../../context/AuthContext";
import CheckmarkIcon from "../../../assets/svg/CheckmarkIcon";

function SearchBarHeader(props) {
  const { prospects, conversations, activeDealStages, inactiveDealStages } =
    useContext(AuthContext);
  const filterType = props.type;
  // State to determine whether the menu is open or not
  const [menuOpen, setMenuOpen] = useState(false);

  function handleFilterClick() {
    // Toggle the menu state when filter button is clicked
    setMenuOpen((prevState) => !prevState);
  }

  return (
    <div className={styles.search_layout}>
      <div className={styles.search_bar}>
        <img
          className={styles.search_icon}
          src="/images/GeneralIcons/SearchIcon.png"
          alt="search"
        ></img>
        <div className={styles.search_divider}></div>
        <input
          placeholder="Search"
          className={styles.search_input}
          value={props.searchTerm}
          onChange={(e) => props.setSearchTerm(e.target.value)}
        ></input>
      </div>
      {filterType !== "EditChatbot" && (
        <div className={styles.search_filter} onClick={handleFilterClick}>
          <img
            className={styles.search_filter_icon}
            src="/images/GeneralIcons/FilterIcon.png"
            alt="filter"
          ></img>

          {menuOpen && (
            <div className={styles.filter_menu}>
              <div
                className={styles.filter_section_item_cell}
                onClick={() => props.setSortBy("All")}
              >
                {props.sortBy === "All" && (
                  <CheckmarkIcon
                    width={18}
                    height={18}
                    color="rgba(0, 0, 0, 1)"
                  />
                )}
                All
              </div>
              <div
                className={styles.filter_section_item_cell}
                onClick={() => props.setSortBy("Active")}
              >
                {props.sortBy === "Active" && (
                  <CheckmarkIcon
                    width={18}
                    height={18}
                    color="rgba(0, 0, 0, 1)"
                  />
                )}
                Active
              </div>
              <div
                className={styles.filter_section_item_cell}
                onClick={() => props.setSortBy("Stopped")}
              >
                {props.sortBy === "Stopped" && (
                  <CheckmarkIcon
                    width={18}
                    height={18}
                    color="rgba(0, 0, 0, 1)"
                  />
                )}
                Stopped
              </div>
              <div
                className={styles.filter_section_item_cell}
                onClick={() => props.setSortBy("Call Scheduled")}
              >
                {props.sortBy === "Call Scheduled" && (
                  <CheckmarkIcon
                    width={18}
                    height={18}
                    color="rgba(0, 0, 0, 1)"
                  />
                )}
                Call Scheduled
              </div>
            </div>
          )}
        </div>
      )}
      {props.filterType === "followups" && (
        <div
          className={styles.manage_folllow_ups_button}
          onClick={() => props.setIsEditing(true)}
        >
          Manage Default Follow Ups
        </div>
      )}
    </div>
  );
}

export default SearchBarHeader;
