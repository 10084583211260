import styles from "./navbar.module.css";
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";

export default function Navbar(props) {
  const [isEditingGuidelines, setIsEditingGuidelines] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const firebaseLink =
    "https://console.firebase.google.com/u/0/project/reply-assist/overview";

  const googleCloudPlatformLink =
    "https://console.cloud.google.com/home/dashboard?hl=en&project=reply-assist";

  const dialogFlowLink =
    "https://dialogflow.cloud.google.com/cx/projects/reply-assist/locations/us-central1/agents/b916b298-c6b6-424a-b70b-e82bdbd47541/flows/00000000-0000-0000-0000-000000000000/flow_creation?pageId=START_PAGE";

  const loggingLink =
    "https://console.cloud.google.com/logs/query;cursorTimestamp=2023-12-10T19:09:38.738544Z;duration=PT30M?project=reply-assist";

  const handleLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // const globalGuideline = guidelines.find(
  //   (guide) => guide.intent === "Global Guidelines"
  // );

  // const [currentChatbotTestingVersion, setCurrentChatbotTestingVersion] =
  //   useState(globalGuideline);

  const { logout, profile } = useContext(AuthContext);

  function handleEditGuidelines() {
    setIsEditingGuidelines(!isEditingGuidelines);
  }
  const navigate = useNavigate();
  const navigateTo = (url) => {
    return navigate(url);
  };

  function switchToUserMode() {
    navigate("/dashboard");
  }

  function handleSettings() {
    navigate("/settings");
  }

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();
  const chatbotMenuRef = useRef();
  const toggleAccountMenuRef = useRef();
  const toggleChatbotMenuRef = useRef();

  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const handleNotificationsClick = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (
      toggleAccountMenuRef.current &&
      toggleAccountMenuRef.current.contains(event.target)
    ) {
      if (showMenu) return;
    }

    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }

    if (
      toggleChatbotMenuRef.current &&
      toggleChatbotMenuRef.current.contains(event.target)
    ) {
      if (isOpen) return;
    }

    if (
      chatbotMenuRef.current &&
      !chatbotMenuRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.portal_navbar}>
      <div className={styles.portal_navbar_sidebar_extension}>
        {false && (
          <div className={styles.exit_button} onClick={switchToUserMode}>
            <p className={styles.exit_text}>Exit Developer Mode</p>
          </div>
        )}
      </div>

      <div className={styles.portal_navbar_content}>
        <div className={styles.portal_navbar_left_content}></div>
        <div className={styles.portal_navbar_right_content}>
          {/* <div
            className={styles.navbar_item}
            onClick={handleNotificationsClick}
          >
            <img
              src="/images/NavbarIcons/NotificationsIcon.png"
              alt="icon"
              className={styles.navbar_icon}
            ></img>
          </div> */}
          <div className={styles.container_button} ref={menuRef}>
            {false && (
              <div className={styles.account_navbar_item} onClick={toggleMenu}>
                <img
                  src={profile.profilePicture || "/DefaultAccountImage.png"}
                  alt="icon"
                  className={styles.account_pfp}
                ></img>
                <div className={styles.account_info}>
                  <p className={styles.account_name}>
                    {profile.firstname} {profile.lastname}
                  </p>
                  {profile.position && (
                    <p className={styles.account_position}>
                      {profile.position}
                    </p>
                  )}
                </div>

                <img
                  src="/DropDownIconBlack.png"
                  alt="dropdown"
                  className={`${styles.dropdown_icon_icon} ${
                    showMenu ? styles.rotate_180 : ""
                  }`}
                ></img>
              </div>
            )}
            {false && (
              <div className={styles.menu}>
                <div
                  className={styles.menu_item}
                  onClick={() => handleLink(firebaseLink)}
                >
                  <img
                    className={styles.account_menu_icon}
                    src="/database.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Firebase</p>
                </div>
                <div
                  className={styles.menu_item}
                  onClick={() => handleLink(googleCloudPlatformLink)}
                >
                  <img
                    className={styles.account_menu_icon}
                    src="/server.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Google Cloud</p>
                </div>
                <div
                  className={styles.menu_item}
                  onClick={() => handleLink(dialogFlowLink)}
                >
                  <img
                    className={styles.account_menu_icon}
                    src="/dialogflow.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>DialogFlow CX</p>
                </div>
                <div
                  className={styles.menu_item}
                  onClick={() => handleLink(loggingLink)}
                >
                  <img
                    className={styles.account_menu_icon}
                    src="/logging.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Logging</p>
                </div>
                <div className={styles.menu_item} onClick={handleSettings}>
                  <img
                    className={styles.account_menu_icon}
                    src="/SettingsIcon.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Settings</p>
                </div>
                <div className={styles.menu_item}>
                  <img
                    className={styles.account_menu_icon}
                    src="/logout.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Logout</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
