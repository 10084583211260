import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";
import { storage } from "../services/firebase"; // Import storage separately

import {
  onSnapshot,
  orderBy,
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  where,
  limit,
  updateDoc,
  getCountFromServer,
  startAfter,
} from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [prospects, setProspects] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [followUps, setFollowUps] = useState([]);

  const [callScheduledCount, setCallScheduledCount] = useState([]);
  const [followUpsActiveCount, setFollowUpsActiveCount] = useState([]);

  const [followUpCount, setFollowUpCount] = useState([]);

  const [userId, setUserId] = useState(null);

  const PRODUCTION_API_KEY = "RA-w4M1qT6xY3pV7lK2rJ9hN5cF8gB4aZ3";

  // const fetchUserProspects = async (userId) => {
  //   try {
  //     const userRef = doc(db, "Users", userId);
  //     const prospectsRef = collection(userRef, "prospects");

  //     // Create a query with a limit of 100
  //     const prospectsQuery = query(prospectsRef, limit(100));
  //     const snapshot = await getDocs(prospectsQuery);

  //     if (!snapshot.empty) {
  //       const fetchedProspects = snapshot.docs.map((doc) => doc.data());
  //       setProspects(fetchedProspects);
  //     } else {
  //       console.warn("No prospects found for user", userId);
  //       setProspects([]); // Set prospects to an empty array
  //     }
  //   } catch (error) {
  //     console.error("Error fetching prospects:", error);
  //     setProspects([]); // In case of an error, set prospects to an empty array
  //   }
  // };

  // useEffect(() => {
  //   migrateUserData(userPairs);
  // }, []);

  // const excludeUserIds = [
  //   "3c9f642c-1979-4279-a5b0-a94f3a3cfadc",
  //   "c93d1f69-66f1-4150-9a14-37bc2aed74ce",
  //   "31413",
  // ];

  // const resetFollowUps = async () => {
  //   const url = "https://api.production.replyassist.com/reset-follow-ups";

  //   try {
  //     // Fetch all users from the "Users" collection
  //     const usersSnapshot = await getDocs(collection(db, "Users"));

  //     for (const userDoc of usersSnapshot.docs) {
  //       const userId = userDoc.id;

  //       // Exclude specified users
  //       if (excludeUserIds.includes(userId)) {
  //         continue;
  //       }

  //       // Fetch all prospects for the current user
  //       const prospectsSnapshot = await getDocs(
  //         collection(db, `Users/${userId}/prospects`)
  //       );

  //       for (const prospectDoc of prospectsSnapshot.docs) {
  //         const prospectId = prospectDoc.id;

  //         const request = {
  //           accountID: userId,
  //           prospectID: prospectId,
  //         };

  //         try {
  //           const response = await axios.put(url, request, {
  //             headers: {
  //               Authorization: `Bearer ${PRODUCTION_API_KEY}`,
  //             },
  //           });

  //           console.log("Response:", response.data);
  //           console.log(
  //             `Follow-ups reset for user ${userId} and prospect ${prospectId}`
  //           );
  //         } catch (error) {
  //           console.error(
  //             `Error resetting follow-ups for user ${userId} and prospect ${prospectId}:`,
  //             error
  //           );
  //           if (error.response) {
  //             console.error("Response error data:", error.response.data);
  //           } else {
  //             console.error("Unexpected error:", error);
  //           }
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching users or prospects:", error);
  //   }
  // };

  // useEffect(() => {
  //   resetFollowUps();
  // }, []);

  // const updateProspectsForUser = async (userId) => {
  //   const url = "https://api.production.replyassist.com/reset-follow-ups";

  //   try {
  //     const prospectsSnapshot = await getDocs(
  //       collection(db, `Users/${userId}/prospects`)
  //     );

  //     for (const prospectDoc of prospectsSnapshot.docs) {
  //       const prospectId = prospectDoc.id;

  //       const request = {
  //         accountID: userId,
  //         prospectID: prospectId,
  //       };

  //       try {
  //         const response = await axios.put(url, request, {
  //           headers: {
  //             Authorization: `Bearer ${PRODUCTION_API_KEY}`,
  //           },
  //         });

  //         console.log("Response:", response.data);
  //         console.log(
  //           `Follow-ups reset for user ${userId} and prospect ${prospectId}`
  //         );
  //       } catch (error) {
  //         console.error(
  //           `Error resetting follow-ups for user ${userId} and prospect ${prospectId}:`,
  //           error
  //         );
  //         if (error.response) {
  //           console.error("Response error data:", error.response.data);
  //         } else {
  //           console.error("Unexpected error:", error);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching prospects:", error);
  //   }
  // };

  // useEffect(() => {
  //   updateProspectsForUser("c93d1f69-66f1-4150-9a14-37bc2aed74ce");
  // }, []);

  const userPairs = [
    {
      sourceId: "33252",
      destinationId: "8f94b03f-f121-4229-9a3f-1b8684ea55af",
    },
    {
      sourceId: "33238",
      destinationId: "51af76f6-47ef-42da-870b-77e148aaa4af",
    },
    {
      sourceId: "31413",
      destinationId: "c93d1f69-66f1-4150-9a14-37bc2aed74ce",
    },
  ];

  const migrateUserProspects = async (userPairs) => {
    try {
      for (const pair of userPairs) {
        const { sourceId, destinationId } = pair;

        const sourceProspectsRef = collection(
          db,
          "Users",
          sourceId,
          "prospects"
        );
        const sourceProspectsSnap = await getDocs(sourceProspectsRef);

        if (sourceProspectsSnap.empty) {
          console.warn(
            `No prospects found for source user with ID ${sourceId}.`
          );
          continue;
        }

        for (const docSnap of sourceProspectsSnap.docs) {
          const prospectData = docSnap.data();
          const prospectId = docSnap.id;

          const destinationProspectRef = doc(
            db,
            "Users",
            destinationId,
            "prospects",
            prospectId
          );

          await setDoc(destinationProspectRef, prospectData);

          console.log(
            `Prospect document ${prospectId} copied from user ${sourceId} to user ${destinationId}`
          );
        }
      }
    } catch (error) {
      console.error("Error migrating user prospects: ", error);
    }
  };

  // useEffect(() => {
  //   migrateUserProspects(userPairs);
  // }, []);

  const fetchUserConversationsAndMessages = async (userId) => {
    try {
      // Step 1: Fetch conversations for the user
      const conversationsRef = collection(db, "conversations");
      const q = query(conversationsRef, where("user_id", "==", userId));
      const conversationsSnapshot = await getDocs(q);

      if (conversationsSnapshot.empty) {
        console.warn("No conversations found for user", userId);
        return;
      }

      const conversations = [];
      for (const conversationDoc of conversationsSnapshot.docs) {
        const conversationData = conversationDoc.data();

        // Step 2: Fetch messages for each conversation
        const messagesRef = collection(conversationDoc.ref, "messages");
        const messagesQuery = query(messagesRef, orderBy("timestamp", "desc"));
        const messagesSnapshot = await getDocs(messagesQuery);

        const messages = messagesSnapshot.docs.map((doc) => doc.data());

        // Combine conversation data with its messages
        conversations.push({
          ...conversationData,
          id: conversationDoc.id,
          messages: messages,
        });
      }

      setConversations(conversations);
    } catch (error) {
      console.error("Error fetching conversations and messages:", error);
    }
  };

  const [isLastPage, setIsLastPage] = useState(false);

  const fetchUserFollowUps = async (
    searchTerm = null,
    page = 1,
    pageSize = 50,
    sortBy = "All"
  ) => {
    if (!userId) {
      return;
    }
    try {
      const userRef = doc(db, "Users", userId);
      console.log(userId);
      const followUpsRef = collection(userRef, "prospects");

      const countQuery = query(
        followUpsRef
        // where("followUpSchedule", "!=", null)
      );
      const contactsCountSnapshot = await getCountFromServer(countQuery);
      setFollowUpCount(contactsCountSnapshot.data().count);

      // Query to count call_scheduled
      const callScheduledQuery = query(
        followUpsRef,
        where("call_scheduled", "==", true)
      );
      const callScheduledSnapshot = await getCountFromServer(
        callScheduledQuery
      );
      setCallScheduledCount(callScheduledSnapshot.data().count);

      // Query to count follow_ups_active
      const followUpsActiveQuery = query(
        followUpsRef,
        where("follow_ups_active", "==", false)
      );
      const followUpsActiveSnapshot = await getCountFromServer(
        followUpsActiveQuery
      );
      setFollowUpsActiveCount(followUpsActiveSnapshot.data().count);

      let q;

      if (searchTerm) {
        const normalizedSearchTerm = searchTerm.toLowerCase();
        q = query(
          followUpsRef,
          where("normalized_search", ">=", normalizedSearchTerm),
          where("normalized_search", "<", normalizedSearchTerm + "\uf8ff"),
          orderBy("normalized_search")
        );
      } else {
        switch (sortBy) {
          case "Active":
            q = query(
              followUpsRef,
              where("followUpSchedule", "!=", null),
              orderBy("followUpSchedule"),
              orderBy("prospectInfo.firstName")
            );
            break;
          case "Stopped":
            q = query(
              followUpsRef,
              where("follow_ups_active", "==", false),
              orderBy("follow_ups_active"),
              orderBy("prospectInfo.firstName")
            );
            break;
          case "Call Scheduled":
            q = query(
              followUpsRef,
              where("call_scheduled", "==", true),
              orderBy("call_scheduled"),
              orderBy("prospectInfo.firstName")
            );
            break;
          case "All":
          default:
            q = query(
              followUpsRef,
              where("followUpSchedule", "!=", null),
              orderBy("followUpSchedule"),
              orderBy("prospectInfo.firstName")
            );
            break;
        }
      }

      const totalDocsToFetch = page * pageSize;
      const querySnapshot = await getDocs(query(q, limit(totalDocsToFetch)));
      const allFollowUps = querySnapshot.docs.map((doc) => doc.data());
      console.log(allFollowUps);
      const paginatedFollowUps = allFollowUps.slice(
        (page - 1) * pageSize,
        page * pageSize
      );

      setFollowUps(paginatedFollowUps);

      if (paginatedFollowUps.length < pageSize) {
        setIsLastPage(true);
      } else {
        setIsLastPage(false);
      }
    } catch (error) {
      console.error("Failed to fetch user follow-ups:", error);
      throw error;
    }
  };

  const updateProspectCallScheduled = async (prospectId, increment) => {
    if (!userId || !prospectId) {
      return;
    }

    try {
      const userRef = doc(db, "Users", userId);
      const prospectRef = doc(userRef, "prospects", prospectId);

      const prospectDoc = await getDoc(prospectRef);
      if (!prospectDoc.exists()) {
        console.error("Prospect does not exist");
        return;
      }

      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        console.error("User does not exist");
        return;
      }

      const userData = userDoc.data();
      let currentCallScheduledCount = userData.call_scheduled_count;

      if (currentCallScheduledCount === undefined) {
        currentCallScheduledCount = 0;
      }

      if (increment) {
        await updateDoc(prospectRef, {
          call_scheduled: true,
          follow_ups_active: false,
        });

        await updateDoc(userRef, {
          call_scheduled_count: currentCallScheduledCount + 1,
        });

        setCallScheduledCount(callScheduledCount + 1);
        console.log(
          "Prospect call scheduled, follow-ups deactivated, and call_scheduled_count incremented"
        );
      } else {
        await updateDoc(prospectRef, {
          call_scheduled: false,
        });

        await updateDoc(userRef, {
          call_scheduled_count: Math.max(currentCallScheduledCount - 1, 0),
        });
        setCallScheduledCount(callScheduledCount - 1);
        console.log(
          "Prospect call scheduled set to false and call_scheduled_count decremented"
        );
      }
    } catch (error) {
      console.error("Failed to update prospect call scheduled:", error);
      throw error;
    }
  };

  const updateProspectNickname = async (prospectId, newNickname) => {
    if (!prospectId || !newNickname) {
      console.error("Missing required parameters");
      return;
    }

    try {
      const userRef = doc(db, "Users", userId);
      const prospectRef = doc(userRef, "prospects", prospectId);

      const prospectDoc = await getDoc(prospectRef);
      if (!prospectDoc.exists()) {
        console.error("Prospect does not exist");
        return;
      }

      await updateDoc(prospectRef, {
        nickname: newNickname,
      });

      console.log("Prospect nickname updated successfully");
    } catch (error) {
      console.error("Failed to update prospect nickname:", error);
      throw error;
    }
  };

  const stopProspectFollowUps = async (prospectId) => {
    if (!userId || !prospectId) {
      return;
    }

    const nonSendFollowUp = [
      {
        timestamp: 2524608000,
        message: "NOSEND",
        readableTimestamp: "January 1 at 12:00 AM",
      },
    ];

    try {
      await await updateFollowUps(prospectId, nonSendFollowUp);

      const userRef = doc(db, "Users", userId);
      const prospectRef = doc(userRef, "prospects", prospectId);

      await updateDoc(prospectRef, {
        follow_ups_active: false,
      });

      setFollowUpsActiveCount(followUpsActiveCount + 1);

      console.log("Prospect follow-ups deactivated");
    } catch (error) {
      console.error("Failed to deactivate prospect follow-ups:", error);
      throw error;
    }
  };

  const restartProspectFollowUps = async (prospectId) => {
    if (!userId || !prospectId) {
      return;
    }

    try {
      const newSchedule = await restartFollowUpSchedule(prospectId);
      const userRef = doc(db, "Users", userId);
      const prospectRef = doc(userRef, "prospects", prospectId);

      await updateDoc(prospectRef, {
        follow_ups_active: true,
      });

      setFollowUpsActiveCount(followUpsActiveCount - 1);

      return newSchedule;
    } catch (error) {
      console.error("Failed to deactivate prospect follow-ups:", error);
      throw error;
    }
  };

  async function restartFollowUpSchedule(prospectId) {
    const url =
      "https://api.production.replyassist.com/reset-follow-ups-internal";
    console.log(followUps);
    const request = {
      accountID: userId,
      prospectID: prospectId,
    };

    try {
      const response = await axios.put(url, request, {
        headers: {
          Authorization: `Bearer ${PRODUCTION_API_KEY}`,
        },
      });

      console.log("Response:", response.data);
      return response.data.updatedSchedule;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        return error.response.data;
      } else {
        return { error: "An unexpected error occurred" };
      }
    }
  }

  async function updateFollowUps(prospectId, followUps) {
    const url = "https://api.production.replyassist.com/update-follow-ups";
    console.log(followUps);
    const request = {
      userDbId: userId,
      memberId: prospectId,
      followUps: followUps,
    };

    try {
      const response = await axios.post(url, request, {
        headers: {
          Authorization: `Bearer ${PRODUCTION_API_KEY}`,
        },
      });

      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        return error.response.data;
      } else {
        return { error: "An unexpected error occurred" };
      }
    }
  }

  // const normalizeProspects = async () => {
  //   try {
  //     console.log("Starting normalization process...");

  //     // Step 1: Fetch all users
  //     const usersSnapshot = await getDocs(collection(db, "Users"));
  //     const users = usersSnapshot.docs;
  //     console.log(`Fetched ${users.length} users.`);

  //     // Step 2: Iterate over each user
  //     for (const userDoc of users) {
  //       const userId = userDoc.id;
  //       console.log(`Processing user: ${userId}`);
  //       const userRef = doc(db, "Users", userId);
  //       const prospectsRef = collection(userRef, "prospects");

  //       // Step 3: Fetch all prospects for the current user
  //       const prospectsSnapshot = await getDocs(prospectsRef);
  //       const prospects = prospectsSnapshot.docs;
  //       console.log(
  //         `Fetched ${prospects.length} prospects for user: ${userId}`
  //       );

  //       // Step 4: Iterate over each prospect and update the normalized_search field
  //       for (const prospectDoc of prospects) {
  //         const prospectData = prospectDoc.data();
  //         const normalizedSearch = (
  //           prospectData.prospectInfo.firstName +
  //           " " +
  //           prospectData.prospectInfo.lastName
  //         ).toLowerCase();
  //         const prospectRef = doc(prospectsRef, prospectDoc.id);

  //         // Update the prospect document
  //         await updateDoc(prospectRef, { normalized_search: normalizedSearch });
  //         console.log(
  //           `Updated prospect: ${prospectDoc.id} with normalized_search: ${normalizedSearch}`
  //         );
  //       }
  //     }

  //     console.log("All prospects have been normalized.");
  //   } catch (error) {
  //     console.error("Error normalizing prospects:", error);
  //   }
  // };

  // useEffect(() => {
  //   normalizeProspects();
  // }, []);

  useEffect(() => {
    // const storedUser = localStorage.getItem("authUser");
    if (userId) {
      // const user = JSON.parse(storedUser);
      setCurrentUser(userId);
      fetchUserData(userId);
      // fetchUserProspects(tempUserId);
      fetchUserFollowUps(userId);
      // fetchUserConversationsAndMessages(tempUserId);
    }

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        localStorage.setItem("authUserId", JSON.stringify(userId));
        fetchUserData(userId);
        // fetchUserProspects(userId);
        fetchUserFollowUps(userId);
        fetchUserConversationsAndMessages(userId);
      } else {
        setCurrentUser(null);
        setProfile(null);
        setProspects([]);
        setFollowUps([]);
        setConversations([]);
        localStorage.removeItem("authUser");
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((credential) => {
        setCurrentUser(credential.user);
        localStorage.setItem("authUser", JSON.stringify(credential.user));
        fetchUserData(credential.user.uid);

        return Promise.all([
          // fetchUserProspects(credential.user.uid),
          fetchUserFollowUps(credential.user.uid),
          fetchUserConversationsAndMessages(credential.user.uid),
        ])
          .then(() => {
            return credential;
          })
          .catch((err) => {
            console.error("Error fetching data:", err);
            throw err;
          });
      })
      .catch((error) => {
        setError(error.message);
        throw error;
      });
  };

  const fetchUserData = async (userId) => {
    try {
      const userDocRef = doc(db, "Users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setProfile(userData);
      } else {
        console.warn("No data found for user:", userId);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const updateDefaultFollowUpSchedule = async (newSchedule) => {
    try {
      const userDocRef = doc(db, "Users", userId);
      await updateDoc(userDocRef, { default_follow_ups: newSchedule });
      console.log(
        `Default follow-up schedule updated successfully for user: ${userId}`
      );
    } catch (error) {
      console.error("Error updating default follow-up schedule:", error);
    }
  };

  const createAccount = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password).catch(
      (error) => {
        setError(error.message);
      }
    );
  };

  const updateProspectFollowup = async (prospectId, newFollowupDate) => {
    const userRef = doc(db, "users", currentUser.uid);
    const prospectRef = doc(userRef, "prospects", prospectId);

    try {
      await updateDoc(prospectRef, {
        followup: newFollowupDate,
      });
      console.log("Followup date updated successfully");
    } catch (error) {
      console.error("Error updating followup date:", error);
    }
  };

  const updateAccountProfilePicture = async (userId, file) => {
    try {
      const storageRef = ref(storage, `profile_pictures/${userId}`);

      await uploadBytes(storageRef, file);

      const downloadURL = await getDownloadURL(storageRef);
      const userDocRef = doc(db, "users", currentUser.uid);

      await updateDoc(userDocRef, {
        profilePicture: downloadURL,
      });

      setProfile((prevProfile) => ({
        ...prevProfile,
        profilePicture: downloadURL,
      }));

      console.log("Profile picture updated successfully.");
    } catch (error) {
      console.error("Error updating profile picture:", error);
    }
  };

  const updateUserProfile = async (userID, updatedProfile) => {
    try {
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, updatedProfile);

      setProfile((prevProfile) => ({
        ...prevProfile,
        ...updatedProfile,
      }));

      console.log("Profile updated successfully.");
    } catch (error) {
      console.error("Error updating user profile:", error);
      setError(error.message);
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      setCurrentUser(null);
      setProfile(null);
      localStorage.removeItem("authUser");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        profile,
        createAccount,
        error,
        userId,
        setUserId,
        fetchUserFollowUps,
        fetchUserData,
        currentUser,
        prospects,
        conversations,
        followUps,
        updateProspectFollowup,
        updateAccountProfilePicture,
        updateUserProfile,
        updateFollowUps,
        logout,
        updateDefaultFollowUpSchedule,
        updateProspectCallScheduled,
        stopProspectFollowUps,
        restartProspectFollowUps,
        followUpCount,
        isLastPage,
        callScheduledCount,
        followUpsActiveCount,
        updateProspectNickname,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
// https://www.linkedin.com/drakeorser
// https://www.linkedin.com/daniel-gratz
// https://www.linkedin.com/madalynwhitaker
