import React from "react";
import styles from "./followups.module.css";
import { useState, useContext, useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import FollowUpSchedule from "../../screen_popups/FollowUpSchedule/FollowUpSchedule";
import { toast } from "react-toastify";

import LinkedinIcon from "../../../assets/svg/LinkedinIcon";

import CheckmarkIcon from "../../../assets/svg/CheckmarkIcon";

import { AuthContext } from "../../../context/AuthContext";

import CircularLoader from "../universal_components/CircularLoader/CircularLoader";

import AddNickname from "../../screen_popups/AddNicknamePopup/AddNickname";
import EditIcon from "../../../assets/svg/EditIcon";

const useStyles = makeStyles({
  datePicker: {
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      padding: 0,
      height: "40px",
      paddingLeft: 14,
    },
  },
});

export default function FollowUpCard(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [isRestarting, setIsRestarting] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [isUpdatingCall, setIsUpdatingCall] = useState(false);

  const [createPopup, setCreatePopup] = useState(false);

  const [loadingPopup, setLoadingPopup] = useState(false);

  // const followUp = props.followup;
  // const prospect = props.followup.prospectInfo;

  const [followUpSchedule, setFollowUpSchedule] = useState(() => {
    if (props.followup?.followUpSchedule) {
      const filteredFollowUpSchedule = props.followup.followUpSchedule.filter(
        (followUp) => followUp.message !== "NOSEND"
      );
      return {
        ...props.followup,
        followUpSchedule: filteredFollowUpSchedule,
      };
    }
    return props.followup;
  });

  useEffect(() => {
    // setFollowUpSchedule(props.followup);
    setCallScheduled(followUpSchedule.call_scheduled || false);
    setFollowUpsActive(
      followUpSchedule.follow_ups_active !== undefined
        ? followUpSchedule.follow_ups_active
        : true
    );
  }, [props.followup]);

  const [callScheduled, setCallScheduled] = useState(
    followUpSchedule.call_scheduled || false
  );

  const [followUpsActive, setFollowUpsActive] = useState(
    followUpSchedule.follow_ups_active !== undefined
      ? followUpSchedule.follow_ups_active
      : true
  );

  const {
    updateFollowUps,
    updateProspectCallScheduled,
    stopProspectFollowUps,
    restartProspectFollowUps,
    updateProspectNickname,
  } = useContext(AuthContext);

  function removeAfterComma(inputString) {
    return inputString.split(",")[0];
  }

  const [selectedDate, setSelectedDate] = useState(
    followUpSchedule?.followUpSchedule[0]?.timestamp
      ? new Date(followUpSchedule.followUpSchedule[0].timestamp * 1000)
      : new Date()
  );

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  function handleEdit() {
    setIsEditing(!isEditing);
  }

  const [isLoading, setLoading] = useState(false);

  const updateCallScheduled = async () => {
    setIsUpdatingCall(true);
    if (!callScheduled) {
      await stopFollowUps();
    }
    try {
      setCallScheduled(!callScheduled);
      const response = await updateProspectCallScheduled(
        followUpSchedule.prospectID,
        !callScheduled
      );
      console.log("Successfully updated");
      return response;
    } catch (error) {
      console.error("Failed to update follow-ups:", error);
      return;
    } finally {
      setIsUpdatingCall(false);
    }
  };

  const stopFollowUps = async () => {
    setIsStopping(true);
    try {
      setFollowUpsActive(false);
      const response = await stopProspectFollowUps(followUpSchedule.prospectID);
      setFollowUpSchedule((prevState) => ({
        ...prevState,
        followUpSchedule: [],
      }));
      console.log("Successfully updated");
      // toast.success("Follow Ups Stopped");
      return response;
    } catch (error) {
      console.error("Failed to update follow-ups:", error);
      return;
    } finally {
      setIsStopping(false);
    }
  };

  async function restartFollowUps() {
    setIsRestarting(true);
    try {
      setFollowUpsActive(true);

      const response = await restartProspectFollowUps(
        followUpSchedule.prospectID
      );
      const newSchedule = response;

      setFollowUpSchedule((prevState) => ({
        ...prevState,
        followUpSchedule: newSchedule,
      }));

      console.log("Successfully updated", response);
      return response;
    } catch (error) {
      console.error("Failed to update follow-ups:", error);
      return;
    } finally {
      setIsRestarting(false);
    }
  }

  async function updateFollowUpsSchedule(followUps) {
    setLoading(true);
    try {
      const response = await updateFollowUps(
        followUpSchedule.prospectID,
        followUps
      );
      console.log("Successfully updated follow-ups:", response);

      setFollowUpSchedule((prevState) => ({
        ...prevState,
        followUpSchedule: followUps,
      }));

      setIsEditing(false);
      setLoading(false);
      toast.success("Follow Up Updated!");
      return response;
    } catch (error) {
      console.error("Failed to update follow-ups:", error);
      toast.error("Error updating follow Up");
      setLoading(false);
      return {
        error: "Failed to execute updateFollowUps",
        details: error.message,
      };
    }
  }

  const updateNickname = async (newNickname) => {
    setLoadingPopup(true);
    const prospectId = props.followup.prospectID;
    try {
      await updateProspectNickname(prospectId, newNickname);
      setFollowUpSchedule((prevState) => ({
        ...prevState,
        nickname: newNickname,
      }));
      console.log("Nickname updated successfully");
    } catch (error) {
      console.error("Failed to update nickname:", error);
    } finally {
      setLoadingPopup(false);
      setCreatePopup(false);
    }
  };

  const getNextFollowUp = (followUpSchedule) => {
    const currentTime = Math.floor(Date.now() / 1000);

    const futureMessage = followUpSchedule.find(
      (followUp) => followUp.timestamp > currentTime
    );

    return futureMessage;
  };

  const handleNavigate = (url) => {
    const urlObj = new URL(url);

    if (!urlObj.pathname.startsWith("/in/")) {
      urlObj.pathname = "/in" + urlObj.pathname;
    }

    window.open(urlObj.toString(), "_blank");
  };

  function formatTimestamp(timestamp) {
    if (!timestamp || isNaN(timestamp)) {
      return "";
    }

    const date = new Date(timestamp * 1000); // Convert the Unix timestamp to milliseconds

    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour time
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${month} ${day} at ${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  const [showNickname, setShowNickname] = useState(false);
  const [editNickname, setEditNickname] = useState(false);

  function handleNameClick() {
    setCreatePopup(true);
  }

  const isLoadingGeneral = isRestarting || isStopping || isUpdatingCall;

  return (
    <div className={styles.follow_up_layout}>
      <AddNickname
        openModal={createPopup}
        closeModal={() => setCreatePopup(false)}
        loading={loadingPopup}
        actionFunction={updateNickname}
        desc={"Add prospect nickname"}
      />

      <FollowUpSchedule
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        updateFunction={updateFollowUpsSchedule}
        followUps={followUpSchedule.followUpSchedule}
        prospect={followUpSchedule.prospectInfo}
        isLoading={isLoading}
      />

      <div className={styles.item_layout_medium}>
        <div className={styles.user_profile_layout}>
          {/* <img
            src="/DefaultAccountImage.png"
            alt="profile"
            className={styles.user_profile_image}
          ></img> */}
          <div className={styles.user_profile_text}>
            <p className={styles.user_profile_name} onClick={handleNameClick}>
              {followUpSchedule.prospectInfo.firstName}{" "}
              {removeAfterComma(followUpSchedule.prospectInfo.lastName)}
            </p>
            {followUpSchedule.nickname && (
              <p className={styles.nickname} onClick={handleNameClick}>
                {followUpSchedule.nickname}
              </p>
            )}
          </div>
          <div onClick={handleNameClick} className={styles.edit_icon_box}>
            <EditIcon width={10} height={10} />
          </div>
          <div
            className={styles.linkedin_button}
            onClick={() =>
              handleNavigate(followUpSchedule.prospectInfo.profileUrl)
            }
          >
            <LinkedinIcon width={20} height={20} />
          </div>
        </div>
      </div>

      <div className={styles.item_layout_mid}>
        {formatTimestamp(
          getNextFollowUp(followUpSchedule.followUpSchedule)?.timestamp
        ) || "-"}
      </div>

      <div className={styles.item_layout_large}>
        <p className={styles.follow_up_info_text}>
          {getNextFollowUp(followUpSchedule.followUpSchedule)?.message || "-"}
        </p>
      </div>

      <div className={styles.buttons}>
        {callScheduled ? (
          <div
            className={styles.is_scheduled_button}
            onClick={!isUpdatingCall ? updateCallScheduled : null}
            style={{
              opacity: isLoading && !isUpdatingCall ? 0.5 : 1,
              pointerEvents: isLoading && !isUpdatingCall ? "none" : "auto",
            }}
          >
            {isUpdatingCall ? (
              <CircularLoader size="20%" />
            ) : (
              <>
                <p className={styles.follow_up_info_text}>Call Scheduled</p>
                <CheckmarkIcon width={18} height={18} />
              </>
            )}
          </div>
        ) : (
          <div
            className={styles.scheduled_button}
            onClick={!isUpdatingCall ? updateCallScheduled : null}
            style={{
              opacity: isLoadingGeneral && !isUpdatingCall ? 0.5 : 1,
              pointerEvents:
                isLoadingGeneral && !isUpdatingCall ? "none" : "auto",
            }}
          >
            {isUpdatingCall ? (
              <CircularLoader size="20%" />
            ) : (
              <p className={styles.follow_up_info_text}>Intro Call Scheduled</p>
            )}
          </div>
        )}
        {followUpsActive ? (
          <div
            className={styles.clear_button}
            onClick={!isStopping ? stopFollowUps : null}
            style={{
              opacity: isLoadingGeneral && !isStopping ? 0.5 : 1,
              pointerEvents: isLoadingGeneral && !isStopping ? "none" : "auto",
            }}
          >
            {isStopping ? (
              <CircularLoader />
            ) : (
              <p className={styles.follow_up_info_text}>Stop</p>
            )}
          </div>
        ) : (
          <div
            className={styles.restart_button}
            onClick={!isRestarting ? restartFollowUps : null}
            style={{
              opacity: isLoadingGeneral && !isRestarting ? 0.5 : 1,
              pointerEvents:
                isLoadingGeneral && !isRestarting ? "none" : "auto",
            }}
          >
            {isRestarting ? (
              <CircularLoader />
            ) : (
              <p className={styles.follow_up_info_text}>Restart</p>
            )}
          </div>
        )}
        {followUpsActive && (
          <div
            className={styles.edit_button}
            onClick={!isLoadingGeneral ? handleEdit : null}
            style={{
              opacity: isLoadingGeneral ? 0.5 : 1,
              pointerEvents: isLoadingGeneral ? "none" : "auto",
            }}
          >
            <p className={styles.follow_up_info_text}>Edit</p>
          </div>
        )}
      </div>
    </div>
  );
}
