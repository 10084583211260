import React from "react";
import styles from "./popup.module.css";
import { useState, useContext, useEffect } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import CancelIcon from "../../../assets/svg/CancelIcon";
import { DynamicFormField } from "../../portal_components/universal_components/DynamicFormField/DynamicFormField";
import { toast } from "react-toastify";
import CircularLoader from "../../portal_components/universal_components/CircularLoader/CircularLoader";

const FollowUpSchedule = ({
  isOpen,
  onClose,
  followUps,
  prospect,
  updateFunction,
  isLoading,
}) => {
  const [editedFollowUps, setEditedFollowUps] = useState(followUps);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (followUps) {
      setEditedFollowUps(followUps);
    }
  }, [followUps]);

  useEffect(() => {
    const checkFollowUpsValidity = editedFollowUps.every(
      (followUp) => followUp.message && followUp.timestamp
    );
    setIsValid(checkFollowUpsValidity);
  }, [editedFollowUps]);

  function closeFunction() {
    setEditedFollowUps(followUps);
    onClose();
  }

  function removeAfterComma(inputString) {
    return inputString.split(",")[0];
  }

  async function saveFollowUps() {
    try {
      await updateFunction(editedFollowUps);
      // toast.success("Follow up schedule updated!");
      onClose();
    } catch (error) {
      toast.error("Error updating follow up schedule");
    }
  }

  function addFollowUp() {
    const newFollowUp = {
      timestamp: Date.now() / 1000, // Current UNIX timestamp
      message: "", // Empty message initially
    };
    setEditedFollowUps([newFollowUp, ...editedFollowUps]);
  }

  const updateFollowUp = (index, updatedFollowUp) => {
    const updatedFollowUps = editedFollowUps.map((followUp, idx) => {
      if (idx === index) {
        return { ...followUp, ...updatedFollowUp };
      }
      return followUp;
    });
    updatedFollowUps.sort((a, b) => a.timestamp - b.timestamp);
    setEditedFollowUps(updatedFollowUps);
  };

  const removeFollowUp = (index) => {
    const updatedFollowUps = editedFollowUps.filter((_, idx) => idx !== index);
    setEditedFollowUps(updatedFollowUps);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.fullscreen_modal}>
      <div className={styles.modal_body}>
        <>
          <div className={styles.modal_header}>
            <div className={styles.header_content}>
              <p className={styles.add_modal_title}>
                Follow Ups for {prospect.firstName}{" "}
                {removeAfterComma(prospect.lastName)}
              </p>
              <div
                className={styles.add_follow_up_button}
                onClick={addFollowUp}
              >
                Add follow up
              </div>
            </div>
          </div>

          <div className={styles.modal_content}>
            {editedFollowUps.map((followup, index) => (
              <FollowUp
                key={`followUp-${index}-${followup.message}`}
                index={index}
                followup={followup}
                updateFollowUp={updateFollowUp}
                removeFollowUp={removeFollowUp}
              />
            ))}
          </div>
          <div className={styles.modal_footer}>
            <div className={styles.buttons}>
              <div className={styles.cancel_button} onClick={closeFunction}>
                Cancel
              </div>

              {isValid ? (
                <div className={styles.save_button} onClick={saveFollowUps}>
                  {isLoading ? (
                    <CircularLoader
                      size="24px"
                      color="rgba(255, 255, 255, 1)"
                    />
                  ) : (
                    "Update"
                  )}
                </div>
              ) : (
                <div className={styles.save_button_mute}>Update</div>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

const FollowUp = ({ index, followup, updateFollowUp, removeFollowUp }) => {
  const [selectedDate, setSelectedDate] = useState(
    followup.timestamp ? new Date(followup.timestamp * 1000) : new Date()
  );
  const [message, setMessage] = useState(followup.message);
  const [isEditing, setIsEditing] = useState(false);
  const [isInPast, setIsInPast] = useState(false);

  useEffect(() => {
    setIsInPast(new Date(selectedDate) < new Date());
  }, [selectedDate]);

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    updateFollowUp(index, { timestamp: newValue.getTime() / 1000 });
  };

  const handleMessageChange = (event) => {
    const newMessage = event.target.value;
    setMessage(newMessage);
  };

  const handleBlur = () => {
    updateFollowUp(index, { message });
    setIsEditing(false);
  };

  const followUpStyle = isInPast
    ? styles.editable_follow_up_past
    : styles.editable_follow_up;

  return (
    <div className={followUpStyle} key={index}>
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            slotProps={{ textField: { size: "small" } }}
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField {...params} inputProps={{ style: { padding: 0 } }} />
            )}
          />
        </LocalizationProvider>
      </div>
      <div
        className={styles.editable_follow_up_message}
        onClick={() => setIsEditing(true)}
      >
        {isEditing || !message ? (
          <textarea
            className={styles.editable_follow_up_message_input}
            type="text"
            value={message}
            onChange={handleMessageChange}
            onBlur={handleBlur}
            autoFocus
            placeholder="Type a message..."
          />
        ) : (
          <span>{message}</span>
        )}
      </div>
      {!isInPast && (
        <div
          className={styles.delete_icon}
          onClick={() => removeFollowUp(index)}
        >
          <CancelIcon />
        </div>
      )}
    </div>
  );
};

export default FollowUpSchedule;
