import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./popup.module.css";
import { AuthContext } from "../../../context/AuthContext";
import CircularLoader from "../../portal_components/universal_components/CircularLoader/CircularLoader";
import CancelIcon from "../../../assets/svg/CancelIcon";
import InfoIconStroke from "../../../assets/svg/InfoIconStroke";
import { toast } from "react-toastify";

const FollowUpMessageOptions = [
  { delay: 1, message: "Thoughts on that last message of mine, by the way?" },
  {
    delay: 2,
    message:
      "Shooting a message your way to keep our conversation fresh & near the top of your inbox!",
  },
  { delay: 2, message: "[firstName]?" },
  { delay: 4, message: "Hey [firstName], remember me?" },
  { delay: 4, message: "Any chance now is a better time to talk?" },
  {
    delay: 8,
    message: "Maybe this message will finally be the right timing? 😂",
  },
  {
    delay: 8,
    message:
      "Hey, I think I've missed the mark with my outreach. To try and spare anyone else from ineffective messaging, I'm curious - what could I have done differently to grab your attention?",
  },
  {
    delay: 16,
    message: "Would it be best for me to not reach out going forward?",
  },
];

const ManageDefaultFollowUps = ({
  isOpen,
  onClose,
  defaultFollowUpsSchedule,
  setDefaultFollowUpsSchedule,
}) => {
  const [editedDefaultFollowUps, setEditedDefaultFollowUps] = useState(
    defaultFollowUpsSchedule
  );

  useEffect(() => {
    if (defaultFollowUpsSchedule) {
      setEditedDefaultFollowUps(defaultFollowUpsSchedule);
    }
  }, [defaultFollowUpsSchedule]);

  const endOfListRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const { updateDefaultFollowUpSchedule } = useContext(AuthContext);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const checkFollowUpsValidity = editedDefaultFollowUps.every(
      (followUp) => followUp.message && followUp.delay
    );
    setIsValid(checkFollowUpsValidity);
  }, [editedDefaultFollowUps]);

  function closeFunction() {
    setEditedDefaultFollowUps(
      defaultFollowUpsSchedule || FollowUpMessageOptions
    );
    onClose();
  }

  async function saveUpdateedFollowUpSchedule() {
    setLoading(true);
    try {
      await updateDefaultFollowUpSchedule(editedDefaultFollowUps);
      setDefaultFollowUpsSchedule(editedDefaultFollowUps);
      toast.success("Default schedule updated!");
      onClose();
      setLoading(false);
    } catch (error) {
      toast.error("Error updating schedule");
      setLoading(false);
    }
  }

  const updateFollowUpSchedule = (index, newDelay) => {
    const updatedFollowUps = editedDefaultFollowUps.map((followUp, idx) => {
      if (idx === index) {
        return { ...followUp, delay: newDelay };
      }
      return followUp;
    });
    setEditedDefaultFollowUps(updatedFollowUps);
  };

  function addFollowUp() {
    const newFollowUp = {
      delay: 16,
      message: "",
    };
    setEditedDefaultFollowUps([...editedDefaultFollowUps, newFollowUp]);
    scrollToBottom();
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      endOfListRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 100);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.fullscreen_modal}>
      <div className={styles.modal_body}>
        <>
          <div className={styles.modal_header}>
            <div className={styles.header_content}>
              <div className={styles.header_content_text}>
                <p className={styles.add_modal_title}>
                  Manage Default Follow Ups Schedule
                </p>
                <p className={styles.add_modal_subtitle}>
                  This schedule restarts everytime you manually send a message
                </p>
              </div>
              <div
                className={styles.add_follow_up_button}
                onClick={addFollowUp}
              >
                Add default follow up
              </div>
            </div>
          </div>

          <div className={styles.modal_content}>
            {editedDefaultFollowUps.map((followUp, index) => {
              return (
                <FollowUp
                  key={`followUp-${index}-${followUp.message}`}
                  index={index}
                  followUp={followUp}
                  editedDefaultFollowUps={editedDefaultFollowUps}
                  setEditedDefaultFollowUps={setEditedDefaultFollowUps}
                  updateFollowUpSchedule={updateFollowUpSchedule}
                />
              );
            })}
            <div ref={endOfListRef} />
          </div>
          <div className={styles.modal_footer}>
            <div className={styles.info_tag}>
              <InfoIconStroke width={20} height={20} />
              <p>
                This will update all{" "}
                <span className={styles.info_tag_span}>future</span> follow ups,
                not past
              </p>
            </div>
            <div className={styles.buttons}>
              <div className={styles.cancel_button} onClick={closeFunction}>
                Cancel
              </div>

              {isValid ? (
                <div
                  className={styles.save_button}
                  onClick={saveUpdateedFollowUpSchedule}
                >
                  {isLoading ? (
                    <CircularLoader
                      size="24px"
                      color="rgba(255, 255, 255, 1)"
                    />
                  ) : (
                    "Update"
                  )}
                </div>
              ) : (
                <div className={styles.save_button_mute}>Update</div>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

function FollowUp({
  index,
  followUp,
  editedDefaultFollowUps,
  setEditedDefaultFollowUps,
  updateFollowUpSchedule,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [localMessage, setLocalMessage] = useState(followUp.message);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const follow_up_times = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
  ];

  function handleDropdownClick() {
    setShowMenu(!showMenu);
  }

  function handleTimeClick(time) {
    updateFollowUpSchedule(index, time);
    setShowMenu(false);
  }

  const handleMessageChange = (event) => {
    setLocalMessage(event.target.value);
  };

  const removeAtIndex = (index) => {
    const updatedFollowUps = editedDefaultFollowUps.filter(
      (_, idx) => idx !== index
    );
    setEditedDefaultFollowUps(updatedFollowUps);
  };

  const handleTextareaFocus = () => {
    setIsEditing(true);
  };

  const handleTextareaBlur = () => {
    const updatedFollowUps = editedDefaultFollowUps.map((f, idx) => {
      if (idx === index) {
        return { ...f, message: localMessage };
      }
      return f;
    });
    setEditedDefaultFollowUps(updatedFollowUps);
    setIsEditing(false);
  };

  return (
    <div className={styles.editable_follow_up}>
      <div className={styles.left_align}>
        <p className={styles.follow_up_number}>Follow Up {index + 1}</p>
        <div
          className={`${styles.editable_follow_up_message} ${
            !isEditing ? styles.editable_follow_up_message_hover : ""
          }`}
          onClick={handleTextareaFocus}
        >
          {isEditing || !localMessage ? (
            <textarea
              className={styles.editable_follow_up_message_input}
              type="text"
              value={localMessage}
              onChange={handleMessageChange}
              onBlur={handleTextareaBlur}
              autoFocus
              placeholder="Type a message..."
            />
          ) : (
            <span>{localMessage}</span>
          )}
        </div>
      </div>
      <div className={styles.right_align}>
        <div
          ref={menuRef}
          className={styles.follow_up_delay}
          onClick={handleDropdownClick}
        >
          {index === 0
            ? `${followUp.delay} Week${
                followUp.delay === 1 ? "" : "s"
              } after you send a message`
            : `${followUp.delay} Week${
                followUp.delay === 1 ? "" : "s"
              } after previous Follow Up`}
          <img
            src="/DropDownIconBlack.png"
            alt="dropdown"
            className={`${styles.dropdown_icon_icon} ${
              showMenu ? styles.rotate_180 : ""
            }`}
          ></img>
          {showMenu && (
            <div className={styles.dropdown_menu} onClick={handleDropdownClick}>
              {follow_up_times.map((time, index) => (
                <div
                  key={index}
                  className={styles.follow_up_times}
                  onClick={() => handleTimeClick(time)}
                >
                  {time} Week{time === 1 ? "" : "s"}
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className={styles.delete_icon}
          onClick={() => removeAtIndex(index)}
        >
          <CancelIcon />
        </div>
      </div>
    </div>
  );
}

export default ManageDefaultFollowUps;
