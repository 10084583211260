import React from "react";
import styles from "./page.module.css";

function PageControl({ prevFunction, nextFunction, isLastPage }) {
  return (
    <div className={styles.page_controls}>
      <div className={styles.page_control} onClick={prevFunction}>
        Prev Page
      </div>
      {!isLastPage ? (
        <div className={styles.page_control} onClick={nextFunction}>
          Next Page
        </div>
      ) : (
        <div className={styles.page_control_disabled}>Next Page</div>
      )}
    </div>
  );
}

export default PageControl;
