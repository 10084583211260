import React from "react";
import { useState } from "react";
import styles from "./prospects.module.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import InterestTag from "../universal_components/interest_tag";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useEffect } from "react";
import { useRef } from "react";

const useStyles = makeStyles({
  datePicker: {
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      padding: 0,
      height: "40px",
      paddingLeft: 14,
      // border: "none",
      // outline: "none",
      // backgroundColor: "blue",
    },
  },
});

export default function Profile(props) {
  const {
    updateProspectFollowup,
    updateProspectDealStage,
    activeDealStages,
    inactiveDealStages,
  } = useContext(AuthContext);
  const [selectedDate, setSelectedDate] = useState(
    props.prospect.followup ? props.prospect.followup.toDate() : new Date()
  );

  const [dealStage, setDealStage] = useState(props.prospect.deal_stage);
  const [interestLevel, setInterestLevel] = useState(
    props.prospect.interest_level
  );

  // const active_deal_stages = [
  //   "Introduction Call Scheduled",
  //   "Qualified Lead",
  //   "Closing Call Scheduled",
  //   "Waiting on Decision",
  //   "Closed Client",
  // ];
  // const inactive_deal_stages = ["Future Sale", "Currently Unfit"];

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    updateProspectFollowup(props.prospect.id, newValue);
  };

  const handleDealStageChange = (newValue) => {
    const upperCaseStage = newValue.toUpperCase();
    setDealStage(upperCaseStage);
    updateProspectDealStage(props.prospect.id, upperCaseStage);
  };

  const classes = useStyles();

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener("mousedown", handleClickOutside);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.user_profile}>
      <div className={styles.user_profile_layout}>
        <img
          src={props.prospect.profile_image}
          alt="profile"
          className={styles.user_profile_image}
        ></img>
        <div className={styles.user_profile_text}>
          <p className={styles.user_profile_name}>
            {props.prospect.firstName} {props.prospect.lastName}
          </p>
          <p className={styles.user_profile_genre}>{props.prospect.position}</p>
        </div>
      </div>
      <div className={styles.follow_up_layout}>
        {/* <p className={styles.cell_text_content}>
          {props.prospect.next_follow_up}
        </p> */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className={classes.datePicker}>
            <DateTimePicker
              value={selectedDate}
              onChange={(newValue) => handleDateChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    style: { padding: 0 },
                  }}
                />
              )}
            />
          </div>
        </LocalizationProvider>
      </div>
      <div className={styles.deal_stage_layout} onClick={toggleMenu}>
        <img
          src="./DropDownIcon.png"
          alt="dropdown"
          className={styles.dropdown_icon}
        ></img>
        {showMenu && (
          <div className={styles.deal_stage_menu}>
            <div className={styles.active_menu}>
              <div className={styles.menu_title_item}>
                <p className={styles.menu_title}>Active</p>
              </div>
              {activeDealStages.map((stage, index) => {
                return (
                  <div
                    className={styles.menu_item}
                    onClick={() => handleDealStageChange(stage)}
                  ></div>
                );
              })}
            </div>
            <div className={styles.inactive_menu}>
              <div className={styles.menu_title_item}>
                <p className={styles.menu_title}>Inactive</p>
              </div>
              {inactiveDealStages.map((stage, index) => {
                return (
                  <div
                    className={styles.menu_item}
                    onClick={() => handleDealStageChange(stage)}
                  ></div>
                );
              })}
            </div>
            {/* <div className={styles.menu_item}>
              <p className={styles.menu_item_text}>Move Deal</p>
            </div>
            <div className={styles.menu_item}>
              <p className={styles.menu_item_text}>Archive</p>
            </div>
            <div className={styles.menu_item}>
              <p className={styles.menu_item_text}>Edit</p>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
